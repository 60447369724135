#dashboard h3 {
  background-color: rgb(31, 78, 121);
  color: white;
}

#dashboard h4 {
  background-color: #06b2ed;
  color: white;
  text-align: left;
}

#dashboard th {
  background-color: #a6a6a6;
  color: white;
  font-weight: 400;
}

#dashboard .kpi th {
  color: black;
}

#dashboard th.main {
  background-color: #595959;
  color: white;
}

#dashboard table tr:nth-child(odd)  {
  background-color: #f0f0f0;
}

#dashboard table tr:nth-child(even)  {
  background-color: #e1e1e1;
}

#dashboard table tr td.update {
  background-color: #a6a6a6;
  color: white;
}

#dashboard table {
  width: 100%;
} 

#dashboard table, #dashboard th, #dashboard td {
  border: 1px solid white;
  border-collapse: collapse;
}

.stickFirstColumn tr>th:first-child, .stickFirstColumn tr>td:first-child {
  position: sticky;
  left: 0;
}

#dashboard h4.project {
  background-color: #c00000;
  color: white;
}

#projectDashboard table tr:last-child td{
  height: 100%;
}

#projectList .InovuaReactDataGrid__column-header {
  background-color: #a6a6a6;
  color: black;
}

#projectList .InovuaReactDataGrid__row--odd {
  background: #f0f0f0;
}

#projectList .InovuaReactDataGrid__row--even {
  background: #e1e1e1;
}

.kpi td {
  text-align: right;
}

.tableWrap {
  height: 400px;
  overflow: auto;
}

.tableWrap thead{
  position: sticky;
  top: 0;
}

.tableWrap table {
  border-collapse: collapse;
}